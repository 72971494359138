import { IPSTACK_ACCESS_KEY, IPSTACK_URL } from "../../constants/APIKeys";
import { pageCountry } from "../../graphql/reactivities";

export const getPhoneLocale = async (countries, allCountries = false) => {
  const activeCountry = pageCountry();

  let hasCity;

  if (!allCountries) {
    if (activeCountry) {
      hasCity = countries.find(({ slug }) => slug === activeCountry);

      if (hasCity) return hasCity.alpha2.toLowerCase();
    }

    const [{ alpha2, slug }] = countries;
    const countrySlug = slug.toLowerCase();

    pageCountry(countrySlug);
    localStorage.setItem("pageCountry", JSON.stringify(countrySlug));

    return alpha2.toLowerCase();
  }

  const userCountry = {};

  await fetch(`${IPSTACK_URL}${IPSTACK_ACCESS_KEY}`)
    .then((response) => response.json())
    .then((data) => {
      const { latitude: IPLatitude = 0, longitude: IPLongitude = 0, country_code: country_code_high } = data;

      const country_code = country_code_high?.toLowerCase();

      // if (hasCity && allCountries) return { country_code };

      userCountry.country_code = country_code;

      let shortestDistance = Infinity;

      for (const { latitude = 0, longitude = 0, slug, alpha2 } of countries) {
        if (country_code === alpha2?.toLowerCase()) {
          userCountry.alpha2 = alpha2.toLowerCase();
          userCountry.slug = slug.toLowerCase();
          break;
        }

        const distance = Math.sqrt(Math.pow(latitude - IPLatitude, 2) + Math.pow(longitude - IPLongitude, 2));

        if (distance < shortestDistance) {
          shortestDistance = distance;

          userCountry.alpha2 = alpha2.toLowerCase();
          userCountry.slug = slug.toLowerCase();
        }
      }
    })
    .catch((_) => {
      const [{ alpha2, slug }] = countries;
      userCountry.alpha2 = alpha2.toLowerCase();
      userCountry.slug = slug.toLowerCase();
    });

  const { alpha2, slug, country_code } = userCountry;

  if (!hasCity) {
    pageCountry(slug);
    localStorage.setItem("pageCountry", JSON.stringify(slug));
  }

  if (allCountries) return country_code;

  return alpha2;
};
