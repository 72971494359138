import { gql } from "@apollo/client";

export const REFRESH_TOKEN = gql`
  mutation TokenRefresh {
    tokenRefresh {
      token {
        accessToken
        refreshToken
      }
    }
  }
`;

// export const CREATE_CONTACT_REQUEST_BY_PARTNER = gql`
//   mutation CreateContactRequest(
//     $partnerSlug: String
//     $name: String!
//     $phone: String!
//     $siteUrl: String
//     $referral: String
//   ) {
//     createContactRequest(
//       partnerSlug: $partnerSlug
//       name: $name
//       phone: $phone
//       siteUrl: $siteUrl
//       referralSlug: $referral
//     ) {
//       ok
//     }
//   }
// `;

export const EMAIL_UPDATE = gql`
  mutation EmailUpdate($email: String!, $password: String, $nextUrl: String!) {
    emailUpdate(email: $email, password: $password, nextUrl: $nextUrl) {
      ok
    }
  }
`;

export const EMAIL_MERGE = gql`
  mutation EmailMerge($email: String!, $nextUrl: String!) {
    emailMerge(email: $email, nextUrl: $nextUrl) {
      ok
    }
  }
`;

export const EMAIL_VERIFY = gql`
  mutation EmailLogin($email: String!, $password: String!) {
    emailLogin(email: $email, password: $password) {
      token {
        accessToken
        refreshToken
      }
    }
  }
`;

export const EMAIL_RESET = gql`
  mutation EmailReset($email: String!, $nextUrl: String!, $errorUrl: String!) {
    emailReset(email: $email, nextUrl: $nextUrl, errorUrl: $errorUrl) {
      ok
    }
  }
`;

export const EMAIL_SIGNUP = gql`
  mutation EmailSignup(
    $nextUrl: String!
    $firstName: String!
    $lastName: String!
    $origin: Int!
    $gender: Int!
    $password: String!
    $email: String!
    $birthday: Date
  ) {
    emailSignup(
      nextUrl: $nextUrl
      firstName: $firstName
      lastName: $lastName
      origin: $origin
      gender: $gender
      password: $password
      email: $email
      birthday: $birthday
    ) {
      user {
        id
        firstName
        lastName
        gender
        birthday
        email
      }
    }
  }
`;

export const EMAIL_PASSWORD_UPDATE = gql`
  mutation EmailPasswordUpdate($password: String!) {
    emailPasswordUpdate(password: $password) {
      ok
    }
  }
`;

export const PHONE_UPDATE = gql`
  mutation PhoneUpdate($phone: String!) {
    phoneUpdate(phone: $phone) {
      ok
    }
  }
`;

export const PHONE_VERIFY = gql`
  mutation PhoneVerify($phone: String!, $code: String!) {
    phoneVerify(phone: $phone, code: $code) {
      token {
        accessToken
        refreshToken
      }
    }
  }
`;

export const PHONE_UPDATE_VERIFY = gql`
  mutation PhoneUpdateVerify($code: String!) {
    phoneUpdateVerify(code: $code) {
      ok
    }
  }
`;

export const PHONE_LOGIN = gql`
  mutation PhoneLogin($phone: String!) {
    phoneLogin(phone: $phone) {
      user {
        id
        firstName
        lastName
        gender
        birthday
      }
    }
  }
`;

export const PHONE_SIGNUP = gql`
  mutation PhoneSignup(
    $phone: String!
    $firstName: String!
    $lastName: String!
    $origin: Int!
    $gender: Int!
    $birthday: Date
  ) {
    phoneSignup(
      phone: $phone
      firstName: $firstName
      lastName: $lastName
      origin: $origin
      gender: $gender
      birthday: $birthday
    ) {
      user {
        id
        firstName
        lastName
        gender
        birthday
      }
    }
  }
`;

export const CREATE_CONTACT_REQUEST = gql`
  mutation CreateContactRequest(
    $clubName: String!
    $name: String!
    $phone: String!
    $partnerSlug: String
    $referral: String
    $city: String
    $options: String
  ) {
    createContactRequest(
      clubName: $clubName
      city: $city
      options: $options
      name: $name
      phone: $phone
      partnerSlug: $partnerSlug
      referralSlug: $referral
    ) {
      ok
    }
  }
`;
